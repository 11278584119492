





import IntroMd from "@/components/IntroMd.vue";
import Vue from "vue";
export default Vue.extend({
  components: { IntroMd },
});
