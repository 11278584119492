







import marked from "marked";
import Vue from "vue";
import axios from "axios";
let rendererMD = new marked.Renderer();
marked.setOptions({
  renderer: rendererMD,
  gfm: true,
  breaks: false,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
});
rendererMD.heading = function (text, level) {
  return `<h${level} style="margin-bottom:20px;margin-top:20px">${text}</h${level}>`;
};
rendererMD.link = function (href, title, text) {
  return `<a style="color:#4183c4;text-decoration: none;" href=${href}>${text}</a>`;
};
export default Vue.extend({
  data: () => ({
    text: ``,
  }),
  computed: {
    markdown: function () {
      return marked(this.text);
    },
  },
  mounted: function () {
    axios
      .post(Vue.prototype.serverAddr + "/api/get-item", {
        collection: "intro",
        need: {
          index: -1,
        },
      })
      .then((response) => {
        let tmp = (response.data as any)[0].content;
        tmp = tmp.replace(/localhost/g, Vue.prototype.serverAddr);
        this.text = tmp;
      });
  },
});
